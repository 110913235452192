import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { getDiscountProducts } from 'services/product.service';

export const QueryDiscountProductsKey = 'getDiscountProducts';

const useDiscountProducts = (currency: string, options?: Record<string, any>) => (
  useQuery(`${QueryDiscountProductsKey}${currency}`, () => getDiscountProducts(currency)
    .then((result: AxiosResponse<Product.Item[]>) => result.data), { ...options }));

export default useDiscountProducts;
