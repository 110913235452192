import Link from 'next/link';
import Image from 'components/core/Image';

import { Quantico } from 'next/font/google';
import classNames from 'classnames';
import styles from './index.module.scss';

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});
const Catalog = ({ image, name, flag, route }: Omit<Category, 'seo'>) => (
  <Link href={route || ''}>
    <div className={styles.catalog}>
      <div className={styles.swatch}>
        {
          image
            ? (
              <>
                <Image
                  className={styles.img}
                  loader={() => image}
                  src={image}
                  alt='Card'
                  width={230}
                  height={150}
                  priority
                  unoptimized
                />
                <Image
                  className={styles.img}
                  loader={() => image}
                  src={image}
                  alt='Card'
                  width={230}
                  height={150}
                  priority
                  unoptimized
                />
                <Image
                  className={styles.img}
                  loader={() => image}
                  src={image}
                  alt='Card'
                  width={230}
                  height={150}
                  priority
                  unoptimized
                />
              </>
            )
            : null
        }
      </div>
      {
        flag
          ? (
            <Image
              className={styles.flag}
              loader={() => flag}
              src={image}
              alt='Card'
              width={32}
              height={32}
            />
          )
          : <span className={classNames(styles.title, quantico.className)}>{name}</span>
      }
    </div>
  </Link>
);

export default Catalog;
