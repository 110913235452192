import Slider from 'react-slick';
import useDiscountProducts from 'hooks/api/useDiscountProducts';
import useCategories from 'hooks/api/useCategories';
import Catalog from 'components/common/Catalog';
import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import classNames from 'classnames';

import { useTranslation } from 'next-i18next';
import { useCallback, useContext, useMemo } from 'react';
import { AppContext } from 'contexts/';
import { Quantico } from 'next/font/google';
import styles from './index.module.scss';

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
        speed: 500,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
      },
    },
  ],
};

const Home = () => {
  const { currency } = useContext(AppContext);
  const { data: categories = [], isLoading: categoriesLoading } = useCategories();
  const { data: discountProducts = [], isLoading: discountLoading } = useDiscountProducts(currency);
  const { t } = useTranslation('home');

  const renderItem = useCallback((item: Category, idx: number) => (
    <Catalog key={idx.toString()} {...item} route={`/category/${item.link}`} />
  ), []);

  const renderDiscountProduct = useCallback((item: Product.Item, idx: number) => (
    <Card {...item} key={idx.toString()} url={`/product/${item.link}`} btn />
  ), []);

  const categoriesIsLoading = useMemo(() => {
    if (discountLoading) return <div className={styles.loader}><Loader /></div>;
    return null;
  }, [discountLoading]);

  const discountIsLoading = useMemo(() => {
    if (categoriesLoading) return <div className={styles.loader}><Loader /></div>;
    return null;
  }, [categoriesLoading]);

  return (
    <div>
      <meta name='google-site-verification' content='hlPuHWhDQtMgqrc79k0R_1IKiwPe9U09--Ha6kVLZBs' />
      <meta name='cryptomus' content='24c06f31' />
      <div className={styles.sliderTitleContainer}>
        <h1 className={classNames(styles.title, quantico.className)}>{t('titleDiscount')}</h1>
      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.wrapper}>
          {
            discountProducts.length
              ? (
                <Slider
                  className={styles.slider}
                  {...settings}
                >
                  {discountProducts.map(renderDiscountProduct)}
                </Slider>
              )
              : null
            }
          {categoriesIsLoading}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <h1 id='categories' className={classNames(styles.title, quantico.className)}>{t('categories')}</h1>
      </div>
      <div className={styles.catalogContainer}>
        {categories.map(renderItem)}
        {discountIsLoading}
      </div>
    </div>
  );
};

export default Home;
