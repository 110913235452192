import type { NextPage } from 'next';
import GeneralLayout from 'layouts/GeneralLayout';
import HomePage from 'components/pages/Home';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Home: NextPage = () => (
  <GeneralLayout
    title='Hot Card Delivery | Gift Card Online Fast Email Delivery'
    description='Buy (Google Play, iTunes, PSN, etc.) gift cards from us at Hot Card Delivery nationwide'
    keywords='Buy iTunes Gift Cards, iTunes Email Delivery, iTunes Gift Card Online, iTunes Fast Delivery, iTunes Online Delivery, iTunes Gift Certificate, Gift Card, Online Code'
  >
    <HomePage />
  </GeneralLayout>
);

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'home', 'header', 'footer', 'layout', 'card',
    ])),
  },
});

export default Home;
